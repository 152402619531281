import React from "react";
import { Link } from "gatsby";
import { IntlProvider } from "react-intl";
import SEO from "../components/SEO";
import useLanguageInformation from "../hooks/useLanguageInformation";

import shotdokIcon from "../images/shotdok-icon.png";

const NotFoundPage = () => {
  const languageInformation = useLanguageInformation();

  return (
    <IntlProvider
      locale={languageInformation.currentLanguageKey}
      messages={languageInformation.currentTranslation.i18n}
    >
      <SEO title="404 - Seite nicht gefunden" />
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Link to="/" className="inline-flex">
              <span className="sr-only">Shotdok</span>
              <img
                className="h-12 w-auto"
                src={shotdokIcon}
                alt="Shotdok Icon"
              />
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-pink-600 uppercase tracking-wide">
                404 Fehler
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Seite nicht gefunden.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, wir konnten die Seite nicht finden.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-pink-600 hover:text-pink-500"
                >
                  Zur Startseite<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </IntlProvider>
  );
};

export default NotFoundPage;
